import { BlubeemModelsApiPackage, BlubeemModelsApiPackageLine } from '@generated/brinks.schemas';
import Collapse from '@shared//collapse/Collapse';
import { Chevron, Package } from '@shared//svg/icons';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { useToast } from '@utilities/context/ToastContext';
import { ProductType } from '@utilities/interfaces/user';
import { Api } from '@utilities/services/Api';
import { breakpointTabletPortrait } from '@utilities/settings/media';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useClickAway from 'react-use/lib/useClickAway';

interface SelectedPackageProps {
  productid: ProductType.BRINKS | ProductType.BLUEBEEM;
}

const SelectedPackage: React.FC<SelectedPackageProps> = ({ productid }) => {
  const [active, setActive] = useState(true);
  const ref = useRef(null);
  const breakpoint = window.innerWidth < breakpointTabletPortrait;
  const { toggleLoader } = useLoader();
  const { makeToast } = useToast();
  const { user, setUser } = useUser();
  const [packageInfo, setPackageInfo] = useState<BlubeemModelsApiPackage>();
  const { packageid } = useParams<{ packageid: string }>();

  const isBrincks = useMemo(() => packageid === ProductType.BRINKS, [packageid]);

  const priceFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });

  useClickAway(
    ref,
    () => {
      if (active && window.innerWidth < breakpointTabletPortrait) setActive(false);
    },
    ['click', 'touchstart'],
  );

  const handleResize = () => {
    const ACTION = window.innerWidth >= breakpointTabletPortrait ? true : false;
    setActive(ACTION);
  };

  const updateActive = () => {
    if (window.innerWidth < breakpointTabletPortrait) {
      setActive(!active);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const getPackageInfoById = useCallback(
    (packageId: string) => {
      toggleLoader(true);
      Api.get<BlubeemModelsApiPackage>(`/Package/${productid}/${packageId}`)
        .then((data: BlubeemModelsApiPackage) => {
          toggleLoader(false);
          setPackageInfo(data);
          setUser({ packageInformation: data });
        })
        .catch((err) => {
          toggleLoader(false);
          makeToast({ message: err.message, variant: 'error' });
        });
    },
    [makeToast, productid, toggleLoader],
  );

  useEffect(() => {
    getPackageInfoById(packageid);
  }, [getPackageInfoById, packageid, productid]);

  if (!packageInfo) {
    return null;
  }

  return (
    <div ref={ref} className={classNames('c-selected-package', { 'c-selected-package--active': active })}>
      <button className="selected-package__button" disabled={!breakpoint} onClick={updateActive}>
        <span className="selected-package__button-title">
          <Package />
          <span>Gekozen voor</span>
        </span>
        <span className="selected-package__state-indicator">
          <Chevron fill="#3C2E72" direction="bottom" />
        </span>
      </button>
      <Collapse active={active}>
        <div className={classNames('selected-package__package-title', { 'brinks-styling': isBrincks })}>
          <div>
            {user.packageInformation.name && (
              <h4 className="selected-package__package-tile-title">{user.packageInformation.name}</h4>
            )}
          </div>
        </div>
        <div className="selected-package__package-tiles">
          {user.packageInformation.packageLines &&
            user.packageInformation.packageLines.map((item: BlubeemModelsApiPackageLine, index) => (
              <section className="selected-package__package-tile" key={index}>
                {item.header && <h4 className="selected-package__package-tile-title">{item.header}</h4>}
                {item.content && <span className="selected-package__package-tile-content">{item.content}</span>}
                {item.subItems && (
                  <ul>
                    {item.subItems.map((subitem: BlubeemModelsApiPackageLine, subitemIndex) => (
                      <li className="selected-package__package-subtile" key={subitemIndex}>
                        {subitem.header && (
                          <h5 className="selected-package__package-subtile-title">{subitem.header}</h5>
                        )}
                        {subitem.content && (
                          <span className="selected-package__package-subtile-content">{subitem.content}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </section>
            ))}
          <div className="selected-package__price">
            <div>
              <span>
                {user.packageInformation.billingMode?.trim()
                  ? `${user.packageInformation.billingMode.trim()}:`
                  : 'Per maand:'}
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: `${priceFormatter.format(user.packageInformation.price)}`,
                }}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SelectedPackage;
